import React, { useEffect, useState } from 'react';
import { getContactUs, addComment, addDeal } from 'store/actions';
import { deleteContactUs } from 'store/actions';
import { useDispatch, useSelector } from 'react-redux';
import "./contactus.css";
import "rsuite/dist/rsuite.css";
import { Drawer, ButtonToolbar, Input } from 'rsuite';
import { Table, Button, Container, Row, Col } from 'reactstrap';
import { Modal, Loader } from 'rsuite';
import { message } from 'antd';
import { EditOutlined } from '@ant-design/icons';
import { initialLifeCycle } from 'pages/Dataset Request/DatasetRequest';
import { formatDDMMYYYY } from 'components/Common/UtilitiesFunctions';

function ContactUs () {
    const [user, setUser] = useState('');
    const [open, setOpen] = useState(false);
    const [modalData, setModalData] = useState('');
    const [Loaderr, setLoaderr] = useState(true);
    const [RecordsNotAvalable, setRecordsNotAvalable] = useState(false);

    const [openn, setOpenn] = React.useState(false);
    const [CommentOpen, setCommentOpen] = React.useState(false);
    const [backdrop, setBackdrop] = React.useState('static');
    const [messagePara, setmessagePara] = useState("-");
    const [commentText, setCommentText] = useState(null);
    const [contactUsPutId, setContactUsPutId] = useState(null);
    const [messageSuccess, setMessageSuccess] = useState(false);

    const [deleteConfirmation, setDeleteConfirmation] = useState(false);
    const [contactToDelete, setContactToDelete] = useState(null);
    const [selectedRows, setSelectedRows] = useState([]);

    const handleDeleteClick = (contactId) => {
        setContactToDelete(contactId);
        setDeleteConfirmation(true);

    };

    const handleConfirmDelete = () => {
        if (contactToDelete) {
            dispatch(deleteContactUs(contactToDelete));
            setDeleteConfirmation(false);
            setContactToDelete(null);
        }
    };

    const { contactss, comment, deals } = useSelector(state => ({
        contactss: state.ContactsUs.users,
        comment: state.contacts.users,
        deals: state.DealsReducer.deals,
    }));

    let dispatch = useDispatch();

    useEffect(() => {
        if (deals?.success && messageSuccess) {
            message.success(deals?.message);
            selectedRows.forEach((id) => {
                let data = [{ url: id }, { move_to_deal: true }];
                dispatch(addComment(data));
                document.querySelector(`#row-${id}`).checked = false;
            });
            document.querySelector(`#all-row-select`).checked = false;
            setMessageSuccess(false);
            // Remove selection of all the rows
            setSelectedRows([]);
        }
    }, [deals]);

    useEffect(() => {

        if (contactss.success) {
            setLoaderr(false);
            localStorage.setItem("ContactUsNewMessage", contactss.result?.length);
            setRecordsNotAvalable(false);
        }
        if (contactss.success == false) {
            setLoaderr(false);
            setRecordsNotAvalable(true);
            localStorage.setItem("ContactUsNewMessage", contactss.result?.length);
        }
    }, [contactss]);

    useEffect(() => {
        dispatch(getContactUs());
        if (localStorage.getItem("authUser")) {
            const userData = JSON.parse(localStorage.getItem("authUser"));
            setUser(userData?.result?.length ? userData?.result[0]?.name : '');
        }
    }, []);

    useEffect(() => {
        if (comment.success && comment.message === 'Records updated successfully.' && messageSuccess) {
            message.success('Comment added successfully.');
            dispatch(getContactUs());
            setMessageSuccess(false);
        }
        if (comment.success && !messageSuccess) {
            dispatch(getContactUs());
        }
    }, [comment]);

    let i = 1;

    const handleOpen = (e) => {
        for (let ii = 0; ii < contactss.result.length; ii++) {
            if (e.target.id == (ii + 2)) {
                setOpenn(true);
                setmessagePara(contactss.result[ii].building);
            }
        }
    };

    const handleClose = () => setOpenn(false);

    const handleCloseComment = () => {
        setCommentOpen(false);
    };

    const handleSaveComment = () => {
        let data = [{ url: contactUsPutId }, { comment: commentText }];
        dispatch(addComment(data));
        setCommentOpen(false);
        setMessageSuccess(true);
    };

    let openModalFunc = (e) => {

        for (let ii = 0; ii < contactss.result.length; ii++) {
            if (e.target.id == (ii + 2)) {
                setModalData(Object.entries(contactss.result[ii].need_help_with));
                setOpen(true);
            }
        }
    };
    let openModalFuncComment = (e) => {
        setCommentOpen(true);
        for (let ii = 0; ii < contactss.result.length; ii++) {
            if (e.target.id == (ii + 2)) {
                setContactUsPutId(contactss.result[ii]._id);
                setCommentText(contactss.result[ii].comment);
            }
        }
    };

    let commentChange = (e) => {
        setCommentText(e);
    };

    const changeRowSelection = (e, id) => {
        if (e.target.checked) {
            if (id === 'all') {
                const selectedRowsList = contactss.result.map(elm => !elm?.move_to_deal ? elm._id : false).filter(elm => elm);
                setSelectedRows(selectedRowsList);
                selectedRowsList.forEach(elm => {
                    document.querySelector(`#row-${elm}`).checked = true;
                });
            } else {
                if ([...selectedRows, id].length === contactss.result.length) {
                    document.querySelector(`#all-row-select`).checked = true;
                }
                setSelectedRows(prev => [...prev, id]);
            }
        } else {
            if (id === 'all') {
                setSelectedRows([]);
                contactss.result.forEach(elm => {
                    document.querySelector(`#row-${elm._id}`).checked = false;
                });
            } else {
                if (document.querySelector(`#all-row-select`).checked) {
                    document.querySelector(`#all-row-select`).checked = false;
                }
                setSelectedRows(prev => [...prev.filter(elm => elm !== id)]);
            }
        }
    };

    const MoveToDeal = () => {
        let dealsData = [];
        for (let index = 0; index < selectedRows.length; index++) {
            const id = selectedRows[index];
            dealsData.push(...contactss.result.map(elm => {
                if (elm._id === id) {
                    return {
                        "owner": user,
                        "client_name": elm?.name,
                        "email": elm?.company_email,
                        "request_date": new Intl.DateTimeFormat('en-GB', {
                            day: 'numeric',
                            month: 'numeric',
                            year: 'numeric'
                        }).format(new Date(elm?.createdAt)),
                        "from_where": elm?.form_fill,
                        "message": elm?.building,
                        "dataset_type": elm.dataset_type ? elm.dataset_type : '',
                        "dataset_sub_type": elm.dataset_sub_type ? elm.dataset_sub_type : '',
                        "language": [],
                        "industry": '',
                        "demography": [],
                        "type": [],
                        "sample_link": '',
                        "page_link": elm?.page_url,
                        "dataset_name": elm?.title,
                        "company_name": elm?.company_name,
                        "volume": elm?.volume,
                        "purpose": elm?.purpose,
                        "budget": elm?.tentative_budget,
                        "service": elm?.service ? elm?.service : '',
                        "lifecycle": initialLifeCycle,
                        "client_type": "",
                        "last_activity": '',
                        "deal_status": 1,
                        "update_date": formatDDMMYYYY(new Date()),
                        "notes": elm?.comment ? elm?.comment : "",
                        "self_created": false,
                        "deal_from": 2,
                        "deal_from_id": elm?._id,
                    };
                } else {
                    return false;
                }
            }).filter(elm => elm));
        }
        const deals = {
            deals: dealsData
        };
        dispatch(addDeal(deals));
        setMessageSuccess(true);
    };

    return (

        <>
            <div className="page-content">
                <Container fluid>
                    <Row>
                        <Col xs="12">
                            <div className="page-title-box pb-2 d-flex align-items-center justify-content-between">
                                <h4 className="mb-0 font-size-18">Contact Us</h4>
                                <Button color='primary' disabled={selectedRows.length <= 0} onClick={MoveToDeal}>Move to deal</Button>
                            </div>
                        </Col>
                    </Row>
                    <div className='contactus-container'>
                        <Table bordered responsive hover className='table table-primary mb-0'>
                            <thead>
                                <tr className='bgdark' >
                                    <th className="border-0  font-medium  bgdark   text-nowrap"><input type="checkbox" className='form-check-input mt-1' id='all-row-select' onChange={(e) => changeRowSelection(e, 'all')} /></th>
                                    <th className='  text-nowrap bgdark'>
                                        No.
                                    </th>
                                    <th className='  text-nowrap bgdark'  >
                                        Name
                                    </th>
                                    <th className='  text-nowrap bgdark ' >
                                        Company  Name
                                    </th>
                                    <th className='  text-nowrap bgdark ' >
                                        Company  Email
                                    </th>

                                    <th className='  text-nowrap bgdark '>
                                        Company web
                                    </th>

                                    <th className='  text-nowrap bgdark ' >
                                        Comment
                                    </th>

                                    <th className='  text-nowrap bgdark '>
                                        Need Help
                                    </th>
                                    <th className='  text-nowrap bgdark ' >
                                        Building
                                    </th>
                                    <th className='  text-nowrap bgdark '>
                                        Date
                                    </th>
                                    <th className='  text-nowrap bgdark '>
                                        Delete
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    !contactss.success && RecordsNotAvalable ? <tr className='records-not-class border-0 w-100'  >
                                        <td colSpan={11} className='text-center bg-transparent text-primary fs-4 border-0'>
                                            Records are not available!
                                        </td>
                                    </tr> : null
                                }
                                <tr>
                                    {
                                        Loaderr ? <td colSpan={11} className="text-center bg-transparent">
                                            <Loader size="lg" className='' />
                                        </td> : null
                                    }
                                </tr>


                                {contactss.success && contactss.result.map((project, index) => <tr key={index} style={project?.move_to_deal ? { backgroundColor: "#a0e2c4" } : {}}>
                                    <td style={project?.move_to_deal ? { backgroundColor: "#a0e2c4" } : {}} className={'text-nowrap'}>
                                        <input type="checkbox" id={`row-${project._id}`} className='form-check-input mt-1' onChange={(e) => changeRowSelection(e, project._id)} disabled={project?.move_to_deal} />
                                    </td>
                                    <td className={project?.move_to_deal ? 'bg-transparent  text-nowrap' : '  text-nowrap'} >{i++}
                                    </td>
                                    <td style={project?.move_to_deal ? { backgroundColor: "#a0e2c4" } : {}} className={'text-nowrap'} >{project.name ? project.name : <p className='text-center'>-</p>}
                                    </td>
                                    <td className={project?.move_to_deal ? 'bg-transparent  text-nowrap' : '  text-nowrap'} >{project.company_name ? project.company_name : <p className='text-center'>-</p>}
                                    </td>
                                    <td className={project?.move_to_deal ? 'bg-transparent  text-nowrap' : '  text-nowrap'} >{project.company_email ? project.company_email : <p className='text-center'>-</p>}
                                    </td>
                                    <td className={project?.move_to_deal ? 'bg-transparent  text-nowrap' : '  text-nowrap'} >{project.company_website ? project.company_website : <p className='text-center'>-</p>}
                                    </td>
                                    <td className={project?.move_to_deal ? 'bg-transparent text-wrap' : '  text-wrap '} >
                                        <div className='d-flex'>
                                            <div className='mx-2' style={{ width: "150px" }}>
                                                {project.comment ? project.comment : "-"}
                                            </div>
                                            <div className=''>
                                                <ButtonToolbar>
                                                    <Button className="bg-primary text-light " id={i} onClick={openModalFuncComment}>{<EditOutlined />} </Button>
                                                </ButtonToolbar>
                                            </div>
                                        </div>
                                    </td>
                                    <td className={project?.move_to_deal ? 'bg-transparent text-nowrap' : ' text-nowrap  '} >
                                        <div className=''>
                                            {project.need_help_with && project.need_help_with.length >= 1 ? <ButtonToolbar>
                                                <Button className="bg-primary text-light " id={i} onClick={openModalFunc}>Show</Button>
                                            </ButtonToolbar> :
                                                <p className='text-center'>-</p>}
                                        </div>
                                    </td>
                                    <td className={project?.move_to_deal ? 'bg-transparent text-nowrap' : 'text-nowrap'} >
                                        <div className=''>
                                            {project.building && project.building.length >= 1 ? <ButtonToolbar>
                                                <Button className='bg-primary text-light' id={i} onClick={handleOpen}> Read</Button>
                                            </ButtonToolbar> : <p className='text-center'>-</p>}
                                        </div>
                                    </td>
                                    <td className={project?.move_to_deal ? 'bg-transparent text-nowrap' : 'text-nowrap'} >{project.createdAt ? project.createdAt.split("T")[0].split("-").reverse().join("/") : <p className='text-center'>-</p>}
                                    </td>

                                    <td className={project?.move_to_deal ? 'bg-transparent text-nowrap' : 'text-nowrap'}>
                                        <Button className='bg-danger text-light' onClick={() => handleDeleteClick(project._id)}>Delete</Button>
                                    </td>



                                </tr>
                                )}


                            </tbody>
                        </Table>
                    </div>

                    <Modal backdrop={backdrop} keyboard={false} open={deleteConfirmation}>
                        <Modal.Header>
                            <Modal.Title>Confirm Deletion</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <p>Are you sure you want to delete this contact?</p>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button onClick={handleConfirmDelete} color='red'>
                                Yes
                            </Button>
                            <Button onClick={() => setDeleteConfirmation(false)}>No</Button>
                        </Modal.Footer>
                    </Modal>

                    <Modal backdrop={backdrop} keyboard={false} open={openn} onClose={handleClose}>
                        <Modal.Header>
                            <Modal.Title>Building</Modal.Title>
                        </Modal.Header>
                        <hr />
                        <Modal.Body>
                            <p>
                                {messagePara}
                            </p>
                        </Modal.Body>
                        <hr />
                        <Modal.Footer>
                            <Button onClick={handleClose} appearance="subtle" className='btn btn-primary'>
                                Cancel
                            </Button>
                        </Modal.Footer>
                    </Modal>

                    <Modal backdrop={backdrop} keyboard={false} open={CommentOpen} onClose={handleCloseComment}>
                        <Modal.Header>
                            <Modal.Title>Comment </Modal.Title>
                        </Modal.Header>
                        <hr />
                        <Modal.Body>
                            <p>
                                <Input as="textarea" value={commentText} rows={3} placeholder="Textarea" className="text-area-size" onChange={commentChange} />
                            </p>
                        </Modal.Body>
                        <hr />
                        <Modal.Footer>
                            <Button onClick={handleCloseComment} appearance="subtle" className='btn btn-secondary'>
                                Cancel
                            </Button>
                            <Button onClick={handleSaveComment} appearance="subtle" className='btn btn-primary'>
                                Save
                            </Button>
                        </Modal.Footer>
                    </Modal>

                    <Drawer id="modal" open={open} onClose={() => setOpen(false)}>
                        <Drawer.Body>
                            {/* {console.log(modalData)}
                     */}

                            <div className="table-responsive">
                                <table className="table
                        table-hover	
                        table-borderless
                        table-primary
                        align-middle">
                                    <thead className="table-light">
                                        <tr>
                                            <th className='bgdark'>No. </th>
                                            <th className='bgdark'>Need Help </th>
                                            {/* <th className='bgdark'>Detail</th> */}
                                        </tr>
                                    </thead>
                                    <tbody className="table-group-divider">
                                        {modalData && modalData.map((x) => <tr key={x[0]} className="table-primary" >
                                            {/* <td scope="row">{} </td> */}
                                            <td scope="row">{x[0] ? Number(x[0]) + 1 : "No Data "} </td>
                                            <td>{x[1] ? x[1].toString() : "No Data"} </td>
                                        </tr>)}
                                    </tbody>
                                </table>
                            </div>
                        </Drawer.Body>
                    </Drawer>

                </Container>
            </div>
        </>
    );
}

export default ContactUs;